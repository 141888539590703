import { Box, Flex, Text } from "@chakra-ui/react";

export default function SpotifySection() {
  return (
    <>
      <Box> 
        <Flex
          justifyContent={'center'}
          alignContent={'center'}
          margin={'auto'}
          wrap={'wrap'}
          width={'80%'}
          paddingTop={'5%'}
          paddingBottom={'5%'}
          flexDirection={'column'}>

            <Box as={'header'}>
              <Text
                className='EBGaramond'
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                color={'#D6C08A'}
                textAlign={'center'}
                paddingBottom={'5%'}
                >Check out our music!
              </Text>
            </Box>
            
            <Flex
              width={"100%"}
              direction={"row"}
              wrap={"wrap"}
              margin={"auto"}
              justifyContent={"space-around"}>

              {/* Spotify */}
              <Box paddingBottom={5}>
                <iframe
                  src="https://open.spotify.com/embed/album/02oBv7IG7HqQy9HNRqN82v?si=vOkdJNWUTJGk1M40j2BFyggenerator=spotify&theme=0"
                  title="spotify album"
                  height="450"
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy">
                </iframe>
              </Box>

              {/* Apple Music */}
              <Box paddingBottom={5}>
                <iframe
                  src="https://embed.music.apple.com/us/album/reflections-ep/1706650345"
                  title="apple music album"
                  allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                  height="450"
                  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation">
                </iframe>
              </Box>
              
            </Flex>
            
        </Flex>
      </Box>
    </>
  )
}