import { Box, Container, Stack, Text, Image, Flex, VStack, SimpleGrid, StackDivider, useColorModeValue, } from '@chakra-ui/react'
import GreenHouseShot from '../Assets/greenHouseShot.jpg'
import "../App.css";

export default function About() {

  return (

    <Box>
      <Container maxW={'7xl'}>
        <SimpleGrid
          columns={{ base: 1, lg: 1 }}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 9, md: 12 }}>
          <Flex direction={'column'} alignItems={'center'}>

            {/* Header */}
            <Box as={'header'}>
              <Text
                className='EBGaramond'
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                color={'#D6C08A'}
                textAlign={'center'}
                paddingBottom={'5%'}
                >About the Band
              </Text>
            </Box>

            <Image
              rounded={'xl'}
              alt={'the band'}
              src={GreenHouseShot}
              fit={'cover'}
              align={'center'}
              w={'80%'}
              h={{ base: '100%', sm: '400px', lg: '500px' }}
            />
          </Flex>

          {/* Band Description Box */}
          <Stack 
            spacing={{ base: 6, md: 10 }}
            className='whiteTextBackground'
            >

            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={'column'}
              divider={
                <StackDivider borderColor={useColorModeValue('gray.200', 'gray.600')} />
              }>
                
                {/* Band Description */}
                <VStack spacing={{ base: 4, sm: 6 }} fontSize={'lg'} textAlign={{ base: 'center' }}>
                  <Text><span className='bold'>Speak Easy</span> is a group of friends that make music together.</Text>

                  <Text>The band is composed of five musicians: <span className='bold'>Patrick Ross Campesi</span> (Lead Guitar/Producer), <span className='bold'>John Bajor</span> (Bass/Manager), <span className='bold'>Katelyn Chopin</span> (Keys/Rhythm Guitar), <span className='bold'>Addison Migues</span> (Vocals), and <span className='bold'>Drake Dugas</span> (Drums/Co-Producer).</Text>

                  <Text>The music of Speak Easy feels like getting a call from an old friend.</Text>
                  
                  <Text>Contrasting the group’s well-received freshman LP, <span className='italic'>"It's All in Your Head"</span>, their new sound conveys a mature reflection of their collective memories, which they skillfully recontextualize to create a sound that is both fresh and nostalgic. Their lyrics are deeply personal, drawing from their individual experiences and the memories that they hold dear.</Text>
                
                  <Text>The music is a mirror, reflecting emotions with fondness and inviting them to look inward and confront their own memories. Each song is a journey into the past, a chance to revisit memories and emotions long forgotten. Fittingly, their anticipated 5-song EP is titled "<span className='italic'>Reflections</span>", out on October 5th.</Text>
                
                  <Text>Through their music, Speak Easy creates a space for introspection and self-reflection. Take your journey through the looking glass, where memories are recontextualized and transformed into something new and beautiful.</Text>
                </VStack>
              
            </Stack>

          </Stack>
        </SimpleGrid>
      </Container>
    </Box>

  )
}