import { Box, Container, Image, Button, Stack, Text, Link, Flex, } from '@chakra-ui/react'
import Reflections from '../Assets/Reflections.jpg'
import "../App.css";

export default function Hero() {
  return (
    <>
      <Box>
        <Container maxW={'80%'}>
          <Stack as={Box} textAlign={'center'} spacing={{ base: 8, md: 14 }} py={{ base: 8, md: 14 }}>

            {/* Newest release */}
            <Stack
              direction={{ base: 'column-reverse', md: 'row' }}
              justify={'space-around'}
              align={'center'}
              paddingTop={{ base: 8, md: 14 }}
              paddingBottom={{ base: 8, md: 14 }}>

              {/* Release info */}
              <Flex
                flexDirection={'column'} 
                className='whiteTextBackground'
                padding={'5%'}
                marginTop={{ base: 8, md: 0 }}>

                <Text
                  as={'span'}
                  color={'#D6C08A'}
                  fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                  className='merriweather'>NEW EP
                </Text>
                
                <Text
                  as={'span'}
                  color={'#D6C08A'}
                  fontSize={{ base: '5xl', sm: '5xl', md: '7xl' }}
                  className='EBGaramond'
                  fontWeight={700}>Reflections
                </Text>

                {/* "Out Now" button */}
                <Link href='https://open.spotify.com/album/02oBv7IG7HqQy9HNRqN82v?si=CFnh6-QcQDGROff9qk9noQ' target='_blank'>
                  <Button colorScheme={'gray'} rounded={'full'}  px={6} _hover={{ bg: '#D6C08A' }}>
                    listen now
                  </Button>
                </Link>

              </Flex>

              {/* Release cover art */}
              <Link href='https://open.spotify.com/album/02oBv7IG7HqQy9HNRqN82v?si=CFnh6-QcQDGROff9qk9noQ' target='_blank'>
                <Image src={Reflections} alt="Reflections Single Art" boxSize={{base: '2xs', md: 'xs'}}></Image>
              </Link>

            </Stack>

          </Stack>
        </Container>
      </Box>
    </>
  )
}