import React from 'react';
import "./App.css";
import { Routes, Route, BrowserRouter } from 'react-router-dom';

// Components
import Footer from './Components/footer';
import NavBar from './Components/navbar';
import Home from './Components/home';
import About from './Components/about';
import Media from './Components/media';
import ReflectionsLyrics from './Components/reflectionsLyrics';

// Fonts
import '@fontsource-variable/eb-garamond'
import '@fontsource/merriweather'

function App() {
  return (
    // Entire Page Div
    <div className='background'>

        <div className='content-wrapper'>

          <BrowserRouter >

            <NavBar />

            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/media' element={<Media />} />
              <Route path='/reflectionslyrics' element={<ReflectionsLyrics />} />
            </Routes>

          </BrowserRouter>

          <Footer />

        </div>

    </div>
  );
}

export default App;
