import React from "react";
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import groupNashvillePhoto from '../Assets/groupNashvillePhoto.png'
import chelseasLiveShot from '../Assets/chelseasLiveShot.jpg'
import groupMirrorPhoto from '../Assets/groupMirrorPhoto.jpg'
import couchPhoto from '../Assets/couchPhoto.jpg'
import groupStarShot from '../Assets/groupStarShot.jpg'
import nashvilleLivePhoto from '../Assets/nashvilleLivePhoto.png'
import { Box } from "@chakra-ui/react";

// Image gallery
const images = [
  {
    original: groupNashvillePhoto,
  },
  {
    original: couchPhoto,
  },
  {
    original: nashvilleLivePhoto,
  },
  {
    original: groupMirrorPhoto,
  },
  {
    original: chelseasLiveShot,
  },
  {
    original: groupStarShot,
  },
];

// Class component render
class ImageCarousel extends React.Component {
  render() {
    return <ImageGallery 
              items={images}
              showFullscreenButton={false}
              showBullets={false}
              showPlayButton={false}
              showThumbnails={false}
              autoPlay={true}
              slideInterval={8000}
              onMouseOver={() => {document.body.style.cursor = "cursor";}}
              onMouseLeave={() => {document.body.style.cursor = "cursor";}}
          />;
  }
}

export default function Hero() {
    return (
      <>
        <Box> 
          <ImageCarousel />
        </Box>
      </>
    )
  }