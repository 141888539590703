import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from "@chakra-ui/react";

import "../App.css";

export default function ReflectionsLyrics() {
    return (
      <Box className="tanBackground" w={{ base: '90%', md: '80%' }} margin={'auto'} marginBottom={15}>

        {/* Header */}
        <Box as={'header'} paddingBottom={5}>
          <Text
            className='EBGaramond'
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
            color={'black'}
            textAlign={'center'}
            >Lyrics
          </Text>
        </Box>

        <Box>

          <Box as={'header'} paddingBottom={5}>
            <Text
              className='merriweather'
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: 'sm', sm: 'sm', lg: 'xl' }}
              color={'black'}
              textAlign={'center'}
              >Reflections
            </Text>
          </Box>

          <Box as={'header'} paddingBottom={5} width={'80%'} margin={'auto'}>
            <Text
              className='merriweather'
              lineHeight={1.1}
              fontWeight={300}
              fontSize={{ base: 'sm', sm: 'sm', lg: 'xl' }}
              color={'black'}
              textAlign={'left'}
              >
                "Reflections" explores the complexities of life's journey, from confronting hidden truths and embracing challenges to finding solace in moments of doubt and vulnerability.<br/><br/>
                Lyrically, the project explores themes of abandonment and self-reliance while encouraging the listener to leave the past behind and take control of their future.<br/><br/>
                Nostalgia often reveals the cracks in the looking glass of our memories, where the truth of our experiences may differ from the idealized images we hold.<br/><br/>
                Ultimately, the EP serves as a poignant reminder that the reflections in the rearview mirror are merely a snapshot of the past, urging us to embrace change and find strength in the ever-evolving nature of life's transformation.<br/>
            </Text>
          </Box>
          {/* Accordion */}
          <Accordion className="whiteTextBackground" allowToggle allowMultiple>

            {/* Journey */}
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as={'span'} flex='1'>
                    <Text
                      color={'#D6C08A'}
                      fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                      className='merriweather'
                      padding={2}
                      fontWeight={600}
                      textAlign={'center'}>1. Journey
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text
                  color={'#D6C08A'}
                  fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                  className='merriweather'
                  textAlign={'center'}>
                    I've been told many times<br/>
                    Don't lie pretty little lies<br/>
                    They'll catch up with you<br/>
                    (Catch up with you)<br/><br/>

                    The journey and the sights<br/>
                    No hours left behind<br/>
                    Put the car in drive<br/><br/>

                    I know it's hard, the hard life<br/>
                    But the rest of the night<br/>
                    We're gonna live just like we used to<br/>
                    (Love just like we used to)<br/><br/>

                    The journey and the sights<br/>
                    No hours left behind<br/>
                    Put the car in drive<br/><br/>

                    Woah<br/>
                    Pound the floor<br/>
                    Yeah<br/>
                    Say it loud say it more<br/>
                    Woah<br/>
                    Bring it like you did before<br/>
                    Yeah<br/>
                    Embrace the journey once more<br/><br/>

                    It know it seems like it's a lie<br/>
                    But it's worth a few tries<br/>
                    And you'll finally know it's true<br/><br/>

                    The journey and the sights<br/>
                    No hours left behind<br/>
                    Put the car in drive<br/><br/>

                    Woah<br/>
                    Pound the floor<br/>
                    Yeah<br/>
                    Say it loud say it more<br/>
                    Woah<br/>
                    Bring it like you did before<br/>
                    Yeah<br/>
                    Embrace the journey once more<br/><br/>

                    Drive away<br/>
                    Don't hesitate<br/>
                    Embrace the journey anyway<br/><br/>

                    Woah<br/>
                    Pound the floor<br/>
                    Yeah<br/>
                    Say it loud say it more<br/>
                    Woah<br/>
                    Bring it like you did before<br/>
                    Yeah<br/>
                    Embrace the journey once more<br/><br/>

                    Embrace the journey once more<br/>
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Be Okay */}
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as={'span'} flex='1'>
                    <Text
                      color={'#D6C08A'}
                      fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                      className='merriweather'
                      padding={2}
                      fontWeight={600}
                      textAlign={'center'}>2. Be Okay
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text
                  color={'#D6C08A'}
                  fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                  className='merriweather'
                  textAlign={'center'}>
                    I told you once<br/>
                    I told you twice<br/>
                    Don't know if I can be your paradise<br/>
                    I know<br/>
                    I know<br/><br/>

                    Try and tell myself that it'll be okay<br/>
                    Keep me in don't let me run away<br/>
                    I know<br/>
                    I know<br/><br/>

                    It's hard to see<br/>
                    You with me<br/>
                    Please convince me<br/>
                    Want you next to me<br/>
                    Wanna learn how to love me<br/>
                    Grow to be<br/>
                    Be okay<br/>
                    Be okay<br/><br/>

                    I know this feeling all to well<br/>
                    Scared, anxious, in a shell<br/>
                    I know<br/>
                    I know<br/><br/>

                    You tell me you see what's wrong<br/>
                    You say it's okay<br/>
                    You're strong enough<br/>
                    You'll be okay<br/><br/>

                    Tomorrow is another day<br/>
                    Let sunlight in before it fades away<br/>
                    I know<br/>
                    I know<br/><br/>

                    It's hard to see<br/>
                    You with me<br/>
                    Please convince me<br/>
                    Want you next to me<br/>
                    Wanna learn how to love me<br/>
                    Grow to be<br/>
                    Be okay<br/>
                    Be okay<br/><br/>

                    Tomorrow is another day<br/>
                    Let sunlight in<br/>
                    Before it fades away<br/>
                    Keep me in don't let me run away<br/>
                    I'll be okay<br/>
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Overture */}
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as={'span'} flex='1'>
                    <Text
                      color={'#D6C08A'}
                      fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                      className='merriweather'
                      padding={2}
                      fontWeight={600}
                      textAlign={'center'}>3. Overture
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text
                  color={'#D6C08A'}
                  fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                  className='merriweather'
                  textAlign={'center'}>
                    You walked out the door<br/>
                    Without saying a word<br/>
                    Guess you could say<br/>
                    You got me good<br/><br/>

                    Don't want you to know<br/>
                    I'm holding on strong<br/>
                    Wanna leave this behind<br/>
                    Want you to all feel alone<br/>
                    Feel alone<br/><br/>
                    
                    Keep me awake<br/>
                    Can't take a break<br/>
                    Feels like I'm paying<br/>
                    For your mistakes<br/><br/>

                    Contemplate the hours<br/>
                    Our overture has let me down<br/>
                    Contemplate your power<br/>
                    But you're on your own<br/>
                    Can't save us now<br/>
                    Our overture has let me down<br/><br/>

                    Hard for me to breath<br/>
                    Air is choking me<br/>
                    Many things I wanna say<br/>
                    You never let me speak<br/><br/>

                    Better by myself<br/>
                    Don't need your help<br/>
                    Let yourself out<br/>
                    No need for farewells<br/>
                    No farewells<br/><br/>

                    Contemplate the hours<br/>
                    Our overture has let me down<br/>
                    Contemplate your power<br/>
                    But you're on your own<br/>
                    Can't save us now<br/>
                    Our overture has let me down<br/><br/>

                    Contemplate the hours<br/>
                    Our overture has let me down<br/>
                    Contemplate your power<br/>
                    But Your on your own<br/>
                    Can't save us now<br/>
                    Our overture has let me down<br/>
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Rearview Mirror/Sunken Wind */}
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as={'span'} flex='1'>
                    <Text
                      color={'#D6C08A'}
                      fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                      className='merriweather'
                      padding={2}
                      fontWeight={600}
                      textAlign={'center'}>4. Rearview Mirror/Sunken Wind
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text
                  color={'#D6C08A'}
                  fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                  className='merriweather'
                  textAlign={'center'}>
                    Driving fast enough to feel the breeze<br/>
                    Excited, long road, no end to see<br/>
                    Wanted, I've wanted to be this free<br/><br/>

                    Wondering what's next In my dreams<br/>
                    Singing this melody on repeat<br/>
                    The rearview mirror puts the past behind me<br/><br/>

                    Seek the scenery<br/>
                    Slow it down<br/>
                    Speak it easy<br/>
                    Stand your ground<br/>
                    Believe your words<br/>
                    Seize the day<br/>
                    Carry yourself<br/>
                    All the way<br/><br/>

                    Build the bridge<br/>
                    Keep it true<br/>
                    It's not a bliss<br/>
                    But it's something new<br/>
                    Hold on tight<br/>
                    Don't let go<br/>
                    Follow through<br/>
                    With what you know<br/><br/>

                    Take the time<br/>
                    To clear your mind<br/>
                    It's okay to cry<br/>
                    It's fair judgement<br/>
                    It's no crime<br/>
                    It's like a sunken wind<br/><br/>

                    Blowing still<br/>
                    Though it fades<br/>
                    It feels so real<br/><br/>

                    Look around you<br/>
                    Take it all in<br/>
                    It's a way to feel<br/>
                    A way to feel<br/><br/>

                    Look around you<br/>
                    Take it all in<br/>
                    It's a way to feel<br/>
                    One with your sunken wind<br/><br/>

                    Oh it's good<br/>
                    But It's gone<br/>
                    A wind that sings<br/>
                    A sunken song<br/>
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Just a Reflection */}
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as={'span'} flex='1'>
                    <Text
                      color={'#D6C08A'}
                      fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                      className='merriweather'
                      padding={2}
                      fontWeight={600}
                      textAlign={'center'}>5. Just a Reflection
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text
                  color={'#D6C08A'}
                  fontSize={{ base: 'md', sm: 'md', md: 'xl' }}
                  className='merriweather'
                  textAlign={'center'}>
                    It's just a reflection<br/>
                    Pay no mind<br/>
                    It's not the future<br/>
                    It's not a sign<br/><br/>

                    In a colored atmosphere<br/>
                    You may feel grey<br/>
                    In a wrongful world<br/>
                    You'll find the right words to say<br/><br/>

                    The truth is frightening<br/>
                    Don't tuck your fears away<br/>
                    Take a breath or two<br/>
                    You don't have to fill the space<br/><br/>

                    In a colored atmosphere<br/>
                    You may feel grey<br/>
                    In a wrongful world<br/>
                    You'll find the right words to say<br/><br/>

                    Don't be cliche<br/>
                    If you stay in one place<br/>
                    Reflections lead you astray<br/>
                    Keep it self made<br/>
                    Drive against the one way<br/>
                    Or reflections lead you astray<br/><br/>

                    Astray, astray<br/>
                    Reflections in the way<br/>
                    You outgrew the grey days<br/>
                    Just a reflection<br/>
                    Pay no mind to it<br/>
                    Reflection, reflection<br/>
                    Just a reflection<br/>
                    Just a reflection<br/><br/>

                    Keep it self made<br/>
                    Drive against the one way<br/>
                    Or reflections lead you astray<br/>
                </Text>
              </AccordionPanel>
            </AccordionItem>

          </Accordion>
        </Box>

      </Box>

      
    );
}