import { Box, Grid, Text } from "@chakra-ui/react";
import PhotoAlbum from "react-photo-album";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import DrakeAtTheRev from '../Assets/DrakeAtTheRev.jpg'
import KateAtTheRev from '../Assets/KateAtTheRev.jpg'
import PatAtTheRev from '../Assets/PatAtTheRev.jpg'
// import KateVarsity from '../Assets/KateVarsity.jpg'
// import JohnFreds from '../Assets/JohnFreds.jpg'
import DrakePhilBradys from '../Assets/DrakePhilBradys.jpg'
import JohnNashville from '../Assets/JohnNashville.png'
import AddieNashville from '../Assets/AddieNashville.png'
import KateLaugh from '../Assets/KateLaugh.jpg'
import HayrideWindow from '../Assets/HayrideWindow.jpg'

import "../App.css";

const photos = [
    { 
      src: PatAtTheRev,
      width: 1080,
      height: 1350
    },
    { 
      src: KateLaugh,
      width: 1080,
      height: 700
    },
    { 
      src: DrakeAtTheRev,
      width: 1080,
      height: 700
    },
    {
      src: AddieNashville,
      width: 1080,
      height: 700
    },
    { 
      src: DrakePhilBradys,
      width: 1080,
      height: 1300
    },
    { 
      src: KateAtTheRev,
      width: 980,
      height: 1550
    },
    { 
      src: JohnNashville,
      width: 1080,
      height: 700
    },
    { 
      src: HayrideWindow,
      width: 1080,
      height: 700
    },

    
];

export default function Media() {
    return (
      <Box w={'80%'} margin={'auto'}>

        {/* Header */}
        <Box as={'header'}>
          <Text
            className='EBGaramond'
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
            color={'#D6C08A'}
            textAlign={'center'}
            paddingBottom={'5%'}
            >Media
          </Text>
        </Box>

        {/* Youtube Videos */}
        <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }} gap={6} paddingBottom={15}>

          {/* Journey (Official Video) */}
          <Box>

            <Text
              textAlign={'center'}
              className='EBGaramond'
              id='whiteTextBackground'
              fontWeight={600}
              fontSize={{ base: 'xl', sm: '2xl', lg: '3xl' }}
              color={'black'}
              width={'80%'}
              margin={'auto'}
              marginBottom={2}>
              Journey (Official Video)
            </Text>

            <LiteYouTubeEmbed 
              id="S7YVFMd37vo"
              title="Journey"
              
            />
          </Box>
          
          {/* Reflections EP Teaser */}
          <Box>

            <Text
              textAlign={'center'}
              className='EBGaramond'
              id='whiteTextBackground'
              fontWeight={600}
              fontSize={{ base: 'xl', sm: '2xl', lg: '3xl' }}
              color={'black'}
              width={'80%'}
              margin={'auto'}
              marginBottom={2}
              >
              Reflections EP Teaser
            </Text>

            <LiteYouTubeEmbed 
              id="Y55UQsVfrtE"
              title="Reflections EP Teaser"
              
            />

          </Box>

          {/* Overthinking */}
          <Box>

            <Text
              textAlign={'center'}
              className='EBGaramond'
              id='whiteTextBackground'
              fontWeight={600}
              fontSize={{ base: 'xl', sm: '2xl', lg: '3xl' }}
              color={'black'}
              width={'80%'}
              margin={'auto'}
              marginBottom={2}>
              Official Audio: Overthinking
            </Text>

            <LiteYouTubeEmbed 
              id="CCarn4d0FM0"
              title="Overthinking"
              
            />

          </Box>

          {/* I Don't Wanna Be Here */}
          <Box>

            <Text
              textAlign={'center'}
              className='EBGaramond'
              id='whiteTextBackground'
              fontWeight={600}
              fontSize={{ base: 'xl', sm: '2xl', lg: '3xl' }}
              color={'black'}
              width={'80%'}
              margin={'auto'}
              marginBottom={2}>
              Official Audio: IDWBH
            </Text>

            <LiteYouTubeEmbed 
              id="4vFGIX-0VKE"
              title="I Don't Wanna Be Here"
              
            />
          </Box>

        </Grid>

        {/* Photo Album */}
        <PhotoAlbum
          layout="rows"
          photos={photos}
        />
      </Box>
    );
}