
import { Box, chakra, Container, Stack, Text, useColorModeValue, VisuallyHidden, Link, Image } from '@chakra-ui/react'
import { FaInstagram, FaYoutube, FaSpotify, FaItunesNote, FaFacebookF, FaLink, FaTiktok } from 'react-icons/fa'
import { ReactNode } from 'react'
import whiteLogo from '../Assets/whiteLogo.svg'

// Logo
const Logo = (props: any) => {
  return (
    <Link href='/'>
        <Text>
            <Image src={whiteLogo} alt="Logo" boxSize='80px'/>
        </Text>
    </Link>
  )
}

// Social Media Icon
const SocialButton = ({ children, label, href, }: 
    {
        children: ReactNode
        label: string
        href: string
    }) =>   {
                return (
                    <chakra.button
                    bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                    rounded={'full'}
                    w={8}
                    h={8}
                    cursor={'pointer'}
                    as={'a'}
                    href={href}
                    target='_blank'
                    display={'inline-flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    transition={'background 0.3s ease'}
                    _hover={{
                        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
                    }}>
                    <VisuallyHidden>{label}</VisuallyHidden>
                    {children}
                    </chakra.button>
                )
            }

export default function SmallWithLogoLeft() {
  return (
    <Box
      // bg={'lightBlue'} style={{  background: 'rgba(135,206,250,0.5)'}} // Transparent blue bg
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <Logo />

        {/* Social Media Icons */}
        <Stack direction={'row'} spacing={6}>
            {/* Spotify */}
            <SocialButton label={'Spotify'} href={'https://open.spotify.com/artist/6DttflSZKb9uIGnXBDugdj?si=9J45oh5RTee6H3Z54mlIAw'}>
                <FaSpotify color='white' size={'md'} />
            </SocialButton>

            {/* Apple Music */}
            <SocialButton label={'Apple Music'} href={'https://music.apple.com/us/artist/speak-easy/1502502987'}>
                <FaItunesNote color='white' size={'md'} />
            </SocialButton>

            {/* Instagram */}
            <SocialButton label={'Instagram'} href='https://www.instagram.com/speakeasybandbr/'>
                <FaInstagram color='white' size={'md'} />
            </SocialButton>

            {/* YouTube */}
            <SocialButton label={'YouTube'} href={'https://www.youtube.com/channel/UCD65hgnt-B7q14mkk5Atszw'}>
                <FaYoutube color='white' size={'md'} />
            </SocialButton>

            {/* TikTok */}
            <SocialButton label={'TikTok'} href={'https://www.tiktok.com/@speakeasybandbr'}>
                <FaTiktok color='white' size={'md'} />
            </SocialButton>

            {/* Facebook */}
            <SocialButton label={'Facebook'} href={'https://www.facebook.com/SpeakEasyBandBR/'}>
                <FaFacebookF color='white' size={'md'} />
            </SocialButton>

            {/* Linktree */}
            <SocialButton label={'Linktree'} href={'https://linktr.ee/speakeasybandbr'}>
                <FaLink color='white' size={'md'} />
            </SocialButton>

        </Stack>

        <Text color='white'>© 2023 Speak Easy</Text>

      </Container>
    </Box>
  )
}