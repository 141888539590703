import ActionButtons from './actionButtons';
import Hero from './hero';
import ImageCarousel from './imageCarousel';
// import InstagramPics from './Components/instagramPics';
import SpotifySection from './spotifySection';
import { Box } from '@chakra-ui/react';

// Instagram URL list
// const instaPicOne = "https://www.instagram.com/p/Cr3YuBvuLSU/?img_index=1"
// const instaPicTwo = "https://www.instagram.com/p/ClmN6GkO1bJ/?img_index=1"

function Home() {
  return (

    // Home Page
    <Box>

        {/* Hero */}
        <Hero />

        {/* Action Buttons */}
        <ActionButtons />

        {/* Image Carousel */}
        <ImageCarousel />

        {/* Instagram Pics */}
        {/* <InstagramPics instaPicOne={instaPicOne} instaPicTwo={instaPicTwo} /> */}

        {/* Spotify Section */}
        <SpotifySection />
        
    </Box>

  );
}

export default Home;