import { Box, Text, Image, Flex } from '@chakra-ui/react'
import KateVarsity from '../Assets/KateVarsity.jpg'
import AddieNashville from '../Assets/AddieNashville.png'
import ChelseasLiveShot2 from '../Assets/chelseasLiveShot2.jpg'

export default function ActionButtons() {

  return (

    // Action Buttons
    <Flex direction={"column"} maxW={'80%'} margin={'auto'} padding={'5%'} paddingTop={0}>

      {/* Card Box */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="center"
        gap={10}
        wrap={"wrap"}
      >

        {/* Media Card */}
        <Box
          maxW="xs"
          mx="auto"
          bg="white"
          shadow="lg"
          rounded="xl"
          _hover={{ transform: "scale(1.05)", cursor: "pointer" }}
          transition= "0.2s ease-in-out"
          onClick={() => window.location.href='/media'}
        >

          {/* Card Text */}
          <Box px={4} py={2}>
            <Text
              color="#D6C08A"
              fontWeight="bold"
              fontSize="3xl"
              className='EBGaramond'
            >
              Media
            </Text>

            <Text
              mt={1}
              fontSize="sm"
              color="#D6C08A"
              className='merriweather'
            >
              Check out our latest music videos, performances, and live shots.
            </Text>
          </Box>

          <Image
            h={48}
            w="full"
            fit="cover"
            mt={2}
            src={KateVarsity}
            alt="Katelyn at the Varsity"
            roundedBottom={"xl"}
          />
        </Box>

        {/* Lyrics Card */}
        <Box
          maxW="xs"
          mx="auto"
          bg="white"
          shadow="lg"
          rounded="xl"
          _hover={{ transform: "scale(1.05)", cursor: "pointer" }}
          transition= "0.2s ease-in-out"
          onClick={() => window.location.href='/reflectionsLyrics'}
        >

          {/* Card Text */}
          <Box px={4} py={2}>
            <Text
              color="#D6C08A"
              fontWeight="bold"
              fontSize="3xl"
              className='EBGaramond'
            >
              Lyrics
            </Text>

            <Text
              mt={1}
              fontSize="sm"
              color="#D6C08A"
              className='merriweather'
            >
              Read the lyrics to our latest EP, Reflections.
            </Text>
          </Box>

          <Image
            h={48}
            w="full"
            fit="cover"
            mt={2}
            src={AddieNashville}
            alt="Addie in Nashville"
            roundedBottom={"xl"}
          />
        </Box>

        {/* Lyrics Card */}
        <Box
          maxW="xs"
          mx="auto"
          bg="white"
          shadow="lg"
          rounded="xl"
          _hover={{ transform: "scale(1.05)", cursor: "pointer" }}
          transition= "0.2s ease-in-out"
          onClick={() => window.location.href='/about'}
        >

          {/* About Text */}
          <Box px={4} py={2}>
            <Text
              color="#D6C08A"
              fontWeight="bold"
              fontSize="3xl"
              className='EBGaramond'
            >
              About
            </Text>

            <Text
              mt={1}
              fontSize="sm"
              color="#D6C08A"
              className='merriweather'
            >
              Learn about the band, who we are, and some insight into our music.
            </Text>
          </Box>

          <Image
            h={48}
            w="full"
            fit="cover"
            mt={2}
            src={ChelseasLiveShot2}
            alt="NIKE AIR"
            roundedBottom={"xl"}
          />
        </Box>

      </Flex>
      
    </Flex>

  )
}